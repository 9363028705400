// Directives
import { mapActions, mapGetters, mapMutations } from "vuex"

// Mixins
import messagesMixin from "./messagesMixin"

// Utils
import {
  validatorEmail,
  validatorPhone,
  validatorZipCode,
} from "../utils/validations/validators"
import { removeEmptyValues } from "@/utils/utils"
import { isIos } from "@/utils/device-utils"

export default {
  mixins: [messagesMixin],
  data() {
    return {
      loading: false,
      order: {
        name: "",
        email: "",
        cel_number: "",
      },
      isMarketplace: this.$route.params.slug === "marketplace",
      address: {
        name: "",
        email: "",
        cel_number: "",
        street: "",
        ext_number: "",
        int_number: "",
        suburb: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        formatted_address: "",
      },
    }
  },
  computed: {
    ...mapGetters("stores", [
      "currentStore",
      "storeReferences",
      "storeHasReferences",
    ]),
    ...mapGetters("cart", ["cart", "cartTotal"]),
    ...mapGetters("stripe", ["storeAccount"]),
    ...mapGetters("landingPage", ["currentDeliveryOption"]),
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    ...mapActions("pendingOrders", [
      "dispatchPendingOrder",
      "dispatchPrivatePendingOrder",
    ]),

    ...mapActions("cart", ["emptyCart"]),

    ...mapActions("stripe", ["checkoutBankTransfer", "checkoutBankCard"]),
    ...mapActions("users", ["findUserByEmailOrPhone"]),
    ...mapMutations("stripe", ["setFundingInstructions"]),

    generatingPaymentSwal() {
      this.$swal({
        title: "Generando pago...",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })
    },

    async handlePaymentWithBankCard(res) {
      try {
        const success_url = window.location.origin + `/orders/${res.id}`

        const response = await this.checkoutBankCard({
          checkout_session: {
            store_account_id: this.storeAccount?.id,
            online_pending_order_id: res.id,
            price: res.pending_order.total,
            success_url,
            cancel_url: window.location.href,
          },
        })

        this.$swal.close()
        if (isIos()) {
          window.location.href = response.session_url
        } else {
          window.open(response.session_url, "_blank")
        }
      } catch (e) {
        throw e
      }
    },
    async handlePaymentWithBankDeposit(res) {
      try {
        const success_url = window.location.origin + `/orders/${res.id}`

        const response = await this.checkoutBankTransfer({
          checkout_session: {
            store_account_id: this.storeAccount && this.storeAccount.id,
            online_pending_order_id: res.id,
            price: res.pending_order.total,
            success_url,
            cancel_url: window.location.href,
          },
        })

        this.$swal.close()
        const fundingInstructions = response.funding_instructions
        this.setFundingInstructions(fundingInstructions)
        return

        console.log(response)
        if (isIos()) {
          window.location.href = response.session_url
        } else {
          return
          window.open(response.session_url, "_blank")
        }
      } catch (e) {
        throw e
      }
    },

    validateAddress() {
      const {
        street,
        ext_number,
        suburb,
        city,
        postal_code,
        formatted_address,
      } = this.address

      const { name, email, cel_number } = this.order

      if (!this.currentUser || this.currentDeliveryOption === "delivery") {
        if (
          !street ||
          !ext_number ||
          !suburb ||
          !city ||
          !postal_code ||
          !name ||
          !cel_number ||
          !formatted_address
        ) {
          this.showErrorToast("Por favor, llena todos los campos")
          return false
        }

        if (!validatorZipCode(postal_code)) {
          this.showErrorToast("Código postal inválido")
          return false
        }

        if (!this.currentUser) {
          if (!validatorEmail(email)) {
            this.showErrorToast("Por favor, introduce tu correo")
            return false
          }
        }
        if (!validatorPhone(cel_number)) {
          this.showErrorToast("Número de teléfono inválido")
          return false
        }

        return true
      }

      return false
    },

    setOrder(
      paymentType,
      deliveryOption = "not_applicable",
      codiPayment = null
    ) {
      const is_stripe_payment_method = ["bank_deposit", "bankcard"].includes(
        paymentType
      )

      const { delivery_date, ...address } = this.address

      let order = {
        name: this.order.name,
        email: this.order.email,
        store_id: this.currentStore.id,
        delivery_date,
        cel_number: this.order.cel_number,
        order_store_products_attributes: Object.values(
          this.cart.reduce((acc, product) => {
            const {
              id,
              units,
              is_bundle,
              bundle_quantity,
              bundle_id,
              store_product_id,
            } = product

            if (acc[id]) {
              acc[id].units += is_bundle ? bundle_quantity * units : units
            } else {
              acc[id] = {
                bundle_store_product_id: store_product_id,
                store_product_id: is_bundle ? store_product_id : id,
                units: is_bundle ? bundle_quantity * units : units,
                is_bundle,
                bundle_quantity: is_bundle ? units : null,
                bundle_id,
              }
            }
            return acc
          }, {})
        ),
        delivery_option: deliveryOption,
        order_mode: "online",
        codi_response_type: codiPayment ? codiPayment : null,
        order_payments_attributes: [
          {
            payment_type: paymentType,
            amount: this.cartTotal,
            payment_method: is_stripe_payment_method ? "stripe" : "manual",
          },
        ],
        address_attributes: {
          ...address,
          name: this.order.name,
        },
        grouped_order_store_products_attributes: [],
        from_store_id: this.$route.query?.from_store_id,
      }

      if (this.storeHasReferences) {
        order.grouped_order_store_products_attributes = Object.values(
          this.cart.reduce((acc, product) => {
            const { id, references, units } = product

            if (acc[id]) {
              acc[id].storeProducts[id] += units
            } else {
              acc[id] = {
                reference_json: JSON.stringify(references),
                storeProducts: { [id]: units },
              }
            }
            return acc
          }, {})
        ).map((group) => ({
          reference_json: group.reference_json,
          store_products: JSON.stringify(group.storeProducts),
        }))
      }

      if (this.currentUser && this.currentDeliveryOption === "pickup") {
        const { address_attributes, ...rest } = order

        return removeEmptyValues(rest)
      }

      return removeEmptyValues(order)
    },

    async handlePayment(paymentType, response) {
      const paymentHandlers = {
        bankcard: this.handlePaymentWithBankCard,
        bank_deposit: this.handlePaymentWithBankDeposit,
      }

      const paymentHandler = paymentHandlers[paymentType]

      if (paymentHandler) {
        await paymentHandler.call(this, response)
      }
    },

    async completeCheckout({
      paymentType,
      deliveryOption,
      codiPayment = null,
    }) {
      // if (
      //   this.currentUser &&
      //   this.currentUser.role_name !== "customer" &&
      //   !this.isMarketplace
      // ) {
      //   this.showErrorToast("Solamente los clientes pueden realizar compras")
      //   this.$bvModal.hide("complete-codi-payment")
      //   throw new Error("Unauthorized")
      // }

      this.loading = true

      const order = this.setOrder(paymentType, deliveryOption, codiPayment)

      try {
        this.generatingPaymentSwal()

        const res = this.currentUser
          ? await this.dispatchPrivatePendingOrder({ order })
          : await this.dispatchPendingOrder({ order })

        await this.handlePayment(paymentType, res)

        this.emptyCart()

        return res
      } catch (e) {
        this.handleFetchError(e.response.data, "No se pudo procesar la orden")
        throw e
      } finally {
        this.$swal.close()
        this.loading = false
        this.$bvModal.hide("complete-codi-payment")
      }
    },
  },
}
